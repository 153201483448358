.label {
  display: flex;
  width: 100%;
  &_search {
    height: 44px;
    max-width: 246px;
    transition: .3s;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    @include adaptive(640) {
      top: 100%;
      bottom: auto;
      max-width: 100%;
      opacity: 0;
      pointer-events: none;
      border: 1px solid $gray;
    }
  }
}

.input {
  width: 100%;
  height: 44px;
  padding: 0 18px;
  border: 1px solid $gray-2;
  border-radius: 3px;
  &_search {
    padding-left: 21px;
    border: none;
  }
}
