
.custom-option:last-of-type {
  border-bottom: 0;
  border-radius: 0 0 4px 4px;
}
.custom-option:hover,
.custom-option.selection {
  background: #f9f9f9;
}

.custom {
  &-select {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.opened {
      .custom-select-arrow {
        transform: rotate(-180deg) translateY(50%);
      }

      .custom-options {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }

    &-wrapper {
      user-select: none;
      display: flex;
      flex-direction: column;
      width: 100%;

      select {
        display: none;
      }
    }
    &-trigger {
      font-size: 14px;
      font-weight: $text-medium;
      line-height: 140%;
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      border: 1px solid #D3D3D3;
      border-radius: 3px;
      background: #fff;
      color: $dark;
      cursor: pointer;
      padding: 0 18px;
    }
  }
  &-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 11;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 3px;
    background: $light;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    border: 1px solid #D3D3D3;
    padding: 11px 0 20px;
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: 1px;
      background: #000000;
    }
  }
  &-option {
    padding: 0 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    cursor: pointer;
    &__text {
      font-size: 13px;
      color: $dark;
      transition: .3s;
    }
    &__num {
      font-size: 12px;
      color: $dark;
    }
  }
  &-description {
    padding: 0 20px;
    font-size: 12px;
    line-height: 140%;
    text-decoration: underline;
    margin-top: 18px;
    cursor: pointer;
  }
}

.option-hover:before {
  background: $gray-4;
}

.custom-select-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  transition: .3s;
  z-index: 3;
  pointer-events: none;
}
