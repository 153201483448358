.sale-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-decoration: none;
  color: $dark;
  z-index: -2;
  &-text {
    text-transform: uppercase;
    padding-right: 10px;
    margin-right: 8px;
    &::before {
      content: '|';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.title {
  font-size: 20px;
  line-height: 100%;
  font-weight: $text-medium;
  margin-bottom: 40px;
}

.page-title {
  font-weight: $text-medium;
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 8px;
  padding: 0;
}
