.checkbox {
  $checkbox: &;
  display: flex;
  user-select: none;
  align-items: center;
  cursor: pointer;

  &__span {
    width: 15px;
    height: 15px;
    display: inline-block;
    flex-shrink: 0;
    margin-right: 6px;
    background-position: center;
    background-size: 3px 3px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 1px solid $dark;

    &_filter {
      border: 1px solid $gray;
      background: $gray-4;
      width: 16px;
      height: 16px;
      margin-right: 18px;
    }
  }

  .checkbox__box:checked + .checkbox__span,
  &_active .checkbox__span {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M9.7071 3.07102L4.43168 8.34608C4.04103 8.7368 3.40733 8.7368 3.01631 8.34608L0.293099 5.62267C-0.0976998 5.23194 -0.0976998 4.59817 0.293099 4.20737C0.683973 3.8165 1.31762 3.8165 1.70832 4.20722L3.7242 6.22313L8.29165 1.65565C8.68253 1.26478 9.31622 1.26508 9.70695 1.65565C10.0977 2.04645 10.0977 2.68 9.7071 3.07102Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='10' height='10' fill='black'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: contain;
    background-position: center;
  }

  &__text {
    font-size: 14px;
    color: $dark;
  }
}
