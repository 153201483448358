.popup {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 140;

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(46, 46, 47, .5);
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &__body {
    width: 100%;
    height: auto;
    /*overflow: auto;*/
    max-width: max-content;
    /*min-width: 500px;*/
    max-height: 100%;
    background: #fff;
    /*overflow-y: auto;*/
    -webkit-overflow-scrolling: touch;
    position: relative;
    color: #000;

    @include adaptive(640) {
      max-width: none;
      max-height: none;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__wrap {
    max-height: 100%;

    @include adaptive(640) {
      height: 100%;
    }

    .simplebar-track.simplebar-vertical {
      &::before {
        display: none;
      }
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: $dark;
      width: 100%;
      border-radius: 0;
    }
  }

  &-target {

  }

  &__close {
    width: 67px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transition: all .6s;
    cursor: pointer;
    z-index: 15;
    background: $light;
    color: $dark;


    &:hover {
      svg {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    & svg {
      transition: all .4s ease-in-out;
      fill: $dark;
      width: 23px;
      height: 23px;
    }
  }

  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    height: max-content;

    @include adaptive(1024) {
      overflow: hidden;
    }

    &__spin {
      display: block;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 5px solid transparent;
      border-top-color: $dark;
      position: relative;
      animation: rotating 2.5s infinite ease;
      margin: auto;

      &:after,
      &:before {
        content: "";
        position: absolute;
        border-radius: inherit;
        border: inherit;
      }

      &:after {
        top: 5px;
        left: 5px;
        width: 180px;
        height: 180px;
        border-top-color: $dark;
        animation: rotating 2s infinite ease;
      }

      &:before {
        top: 15px;
        left: 15px;
        width: 160px;
        height: 160px;
        border-top-color: $dark;
        animation: rotating 1.5s infinite ease;
      }
    }

    @keyframes rotating {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
