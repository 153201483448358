.top-button {
  display: flex;
  align-items: center;
  padding: 12px;
  color: $light;
  background: $dark;
  width: max-content;
  transform: rotate(-90deg);
  position: fixed;
  right: 40px;
  bottom: 212px;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  @include adaptive(1600) {
    right: -15px;
    bottom: 30px;
  }
  @include adaptive(1024) {
    right: 10px;
    bottom: 15px;
  }
  &_visible {
    opacity: 1;
    pointer-events: auto;
  }
  &-text {
    font-size: 14px;
    line-height: 120%;
    margin-right: 5px;
    @include adaptive(1024) {
      display: none;
    }
  }
  &-toggle {
    font-size: 10px;
    bottom: -2px;
    @include adaptive(1024) {
      bottom: auto;
    }
  }
}
