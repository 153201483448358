.header {
  background: $light;
  z-index: 71;
  &-internal {
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-1;
    margin-bottom: 18px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
    width: 100%;
  }

  &-top {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    background: $light;
    @include adaptive(1024) {
      padding: 0 15px;
    }
    @include adaptive(480) {
      padding: 0 10px;
    }
  }

  &-divisions {
    display: flex;
    align-items: center;
    @include adaptive(1024) {
      display: none;
    }

    &-item {
      font-size: 12px;
      font-weight: $text-medium;
      letter-spacing: .1em;
      text-transform: uppercase;
      padding: 24px 8px 18px;
      text-decoration: none;
      color: $dark;
      transition: .3s;
      margin-right: 9px;

      &-text {
        font-size: 13px;
        margin-left: 9px;
        @include adaptive(480) {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: $gray-4;
      }

      &_active {
        background: $gray-4;
      }
    }
  }

  &-interaction {
    display: flex;
    align-items: center;
    @include adaptive(640) {
      position: static;
    }

    &-item {
      color: $gray-3;
      text-decoration: none;
      margin-right: 21px;
      transition: .3s;
      align-items: center;
      display: flex;
      &:hover {
        color: $dark;
      }
      @include adaptive(480) {
        margin-right: 5px;
      }

      &_gold {
        color: #cdb17b;
      }
      &_silver {
        color: #b0b0b0;
      }
      &_blue {
        color: #0ab4a6;
      }
      &_dark {
        color: $dark;
      }
    }
  }

  &-search {
    display: flex;
    box-sizing: border-box;
    border-radius: 3px;
    transition: .3s;
    width: 27px;
    @include adaptive(640) {
      position: static;
    }
    &_active {
      border: 1px solid $gray-2;
      padding-right: 14px;
      width: 283px;
      @include adaptive(640) {
        width: 27px;
        border: none;
        padding-right: 0;
      }
      .label_search {
        width: 100%;
        @include adaptive(640) {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
    &-button {
      margin-left: auto;
      cursor: pointer;
      transition: .3s;
      color: $gray-3;
      &:hover {
        color: $dark;
      }
    }
  }

  &-button {
    color: $gray-3;
  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    max-width: 320px;
    margin-top: -16px;
    margin-bottom: $level2;
    @include adaptive(1024) {
      display: none;
    }

    &-mobile {
      display: none;
      @include adaptive(1024) {
        display: flex;
        width: 100%;
        max-width: 150px;
        margin-right: auto;
      }
      @include adaptive(480) {
        max-width: 120px;
      }
    }
  }

  &-navigation {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: $level3;

    @include adaptive(1024) {
      display: none;
    }

    &-item {
      margin-right: $level8;
      position: static;

      &_inactive {
        .header-navigation-link {
          color: $gray-3;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      letter-spacing: .02em;
      color: $dark;
      text-decoration: none;
    }

    &-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: .3s;
      background: $light;
      box-shadow: 0 500px 0 0 rgba(34, 34, 34, .4);

      &_active {
        opacity: 1;
        pointer-events: auto;
      }
    }

  }

  &-subnavigation {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1340px;
    margin-right: auto;
    margin-left: auto;
    padding: $level1 0 $level11;

    &-item {

    }

    &-link {
      color: $dark;
      line-height: 220%;
      text-decoration: none;
    }

    &-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-img {
        margin-bottom: 16px;
      }
      &-description {
        font-size: 12px;
        line-height: 150%;
        color: #757575;
      }
      &-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 14px;
        text-transform: uppercase;
      }

      &-link {
        font-size: 14px;
        line-height: 150%;
        color: $dark;
      }
    }
  }

  &-subitems {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 757px;
    margin-right: 60px;
  }

  &-subitem {
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &-title {
      color: $dark;
      margin-bottom: $level7;
      font-weight: 500;
      text-decoration: none;

      &_empty {
        margin-bottom: 34px;
      }
    }
  }

  &-list {
    flex-direction: row;
    position: static;
    background: $light;
  }
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  &-divisions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &-item {
      font-size: 11px;
      font-weight: $text-medium;
      letter-spacing: .1em;
      text-transform: uppercase;
      text-decoration: none;
      color: $dark;
      padding: 10px 5px;
      width: calc(100% / 3);
      display: flex;
      align-items: center;
      justify-content: center;
      &_active {
        background: $gray-4;
      }
    }
  }

  &-navigation {
    display: flex;
    flex-direction: column;

    &-item {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $gray-1;

      &-toggle {
        position: absolute;
        right: 0;
        top: 5px;
        font-size: 14px;

        &[aria-expanded="true"] {
          transform: rotate(180deg);
        }
      }
    }

    &-wrapper {
      display: none;
      padding: 5px 0 10px;
    }

    &-link {
      color: $dark;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: .02em;
      text-decoration: none;
    }
  }

  &-subnavigation {
    display: flex;
    flex-direction: column;

    &-link {
      color: $dark;
      text-decoration: none;
    }

    &-card {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-img {
        display: flex;
        width: 100%;
        margin-bottom: 5px;
      }
      &-description {
        font-size: 12px;
        color: $gray-3;
      }
      &-title {
        font-size: 14px;
        font-weight: $text-medium;
        text-transform: uppercase;
      }
      &-link {
        color: $dark;
      }
    }
  }

  &-subitems {
    display: flex;
    flex-direction: column;
  }

  &-subitem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;

    &_toggle {
      padding: 5px 0;
      border-bottom: 1px solid $gray-1;
      border-top: 1px solid $gray-1;
    }

    &-title {
      font-size: 17px;
      &-toggle {
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 14px;

        &[aria-expanded="true"] {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-sublist {

    &-toggle {
      display: none;
      padding-top: 5px;
    }
  }
}
