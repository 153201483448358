.overlay {
  z-index: 70;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(46, 46, 47, .5);

  .body--overlay & {
    z-index: 100;
  }
}