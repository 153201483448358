body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

img {
  max-width: 100%;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
}

* {
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: $font-main;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  overflow-x: hidden;
  min-width: 320px;
  min-height: 100vh;
  color: #2b2e38;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

::-webkit-scrollbar {
  appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: $dark;
  border-radius: 0;
  border: 0;
  transition: all .2s ease-in-out;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  transition: all .2s ease-in-out;
}

a,
label {
  -webkit-tap-highlight-color: transparent;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  clip: rect(0 0 0 0);
}

.container {
  @extend %container;
  max-width: 1304px;

  &_big {
    margin-right: auto;
    margin-left: auto;
    max-width: 1950px;
    width: 100%;
  }

  &_min {
    margin-right: auto;
    margin-left: auto;
    max-width: 1000px;
    width: 100%;
  }

  &_middle {
    @extend %container;
    max-width: 1403px;
  }
}

header,
main,
footer {
  flex-shrink: 0;
}

footer {
  margin-top: auto;
}

.fancybox-navigation {
  position: static;
}
.swiper-container {
  width: 100%;
  max-width: 100%;
}
