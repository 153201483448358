.tabs {
  max-width: 100%;
  width: 100%;
  &-top {
    display: flex;
    width: max-content;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;
    @include adaptive(480) {
      flex-direction: column;
    }
  }
  &__toggle {
    font-weight: $text-medium;
    font-size: 20px;
    line-height: 100%;
    color: $gray-3;
    margin-right: 24px;
    cursor: pointer;
    transition: .3s;
    text-decoration: none;
    padding-bottom: 8px;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 2px;
      background: $dark;
      transition: .3s;
      opacity: 0;
    }
    &:hover {
      color: $dark;
      &::before {
        opacity: 1;
      }
    }
    &_active {
      color: $dark;
      &::before {
        opacity: 1;
      }
    }
    &:last-child {
      margin-right: 0;
      @include adaptive(480) {
        margin-top: 10px;
      }
    }
  }
}
