.img {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  &_cover {
    object-fit: cover;
    height: 100%;
  }
}
