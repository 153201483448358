.button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 44px;
  border-radius: 3px;
  text-decoration: none;
  transition: .3s;
  cursor: pointer;

  &_transparent {
    color: $light;
    background: transparent;
    border: 1px solid $light;

    &:hover {
      background: $light;
      color: $dark;
    }
  }

  &_light {
    color: $dark;
    background: $light;
    border: 1px solid $dark;

    &:hover {
      background: $dark;
      color: $light;
    }
  }

  &_burger {
    height: 20px;
    width: 30px;
    min-width: 0;
    background: $light;
    padding: 0;
  }

  &_small {
    width: 130px;
  }

  &_middle {
    width: 177px;
  }

  &_big {
    width: 208px;
  }

  &_dark {
    color: $light;
    background: $dark;

    &:hover {
      background: $gray-3;
    }
  }
  &_gray {
    color: $light;
    background: $gray;
    &:hover {
      background: $dark;
    }
  }
}

.burger {
  display: none;
  @include adaptive(1024) {
    display: flex;
    margin-left: 21px;
  }
  @include adaptive(480) {
    margin-left: 5px;
  }

  &::before,
  &::after {
    background: currentColor;
    backface-visibility: hidden;
    content: "";
    height: 2px;
    left: 0;
    transition: all .75s;
    width: 100%;
  }

  &::before {
    box-shadow: currentColor 0 9px 0 0;
    position: absolute;
    top: 9px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    left: auto;
  }

  &_open {

    &::before {
      width: 60%;
      top: 50%;
      left: 25%;
      transform: rotate(225deg);
      box-shadow: transparent 0 7px 0 0;
    }

    &::after {
      top: 50%;
      left: 25%;
      transform: rotate(315deg);
      right: auto;
      width: 60%;
    }
  }
}

.whatsapp-button {
  width: 41px;
  height: 42px;
  position: fixed;
  top: 448px;
  right: 0;
  background: $gray;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s;
  @include adaptive(480) {
    top: 250px;
  }

  &:hover {
    background: $gray-3;
  }

  &-icon {
    color: $light;
  }
}

.whatsapp-menu {
  position: fixed;
  top: 448px;
  right: 0;
  border: 1px solid #DDDDDD;
  border-radius: 3px 0 0 3px;
  background: $light;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 302px;
  padding-bottom: 30px;
  transform: translateX(100%);
  transition: .3s;
  @include adaptive(480) {
    top: 100px;
  }
  &_active {
    transform: translateX(0);
  }
  &-header {
    padding: 12px 14px;
    display: flex;
    align-items: center;
    background: $gray-1;
    margin-bottom: 24px;
    width: 100%;
  }
  &-close {
    height: 12px;
    width: 12px;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      height: 15px;
      width: 1px;
      background: $dark;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 15px;
      width: 1px;
      background: $dark;
    }
  }
  &-title {
    font-size: 11px;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
  }

  &-phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark;
    text-decoration: none;
    margin-bottom: 30px;
    &-icon {
      margin-bottom: 14px;
    }
  }
  &-mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark;
    text-decoration: none;
    margin-bottom: 40px;
    &-icon {
      margin-bottom: 10px;
    }
    &-text {
      font-weight: $text-medium;
      &::before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        height: 1px;
        background: $dark;
      }
    }
  }

  &-item {
    &:hover {
      color: $gray-3;
    }
  }

  &-button {
    margin-bottom: 40px;
  }

  &-description {
    max-width: 248px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    a {
      color: $dark;
    }
  }
}
