.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;
  &-item {
    font-weight: $text-medium;
    color: $dark;
    cursor: pointer;
    text-decoration: none;
  }
  &-arrow {
    font-size: 10px;
  }
  &-description {
    font-weight: $text-medium;
  }
}
