.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 320px;
  transform: translateX(100%);
  z-index: -1;
  transition: .3s;
  background: $light;
  padding: 70px 20px 20px 20px;
  overflow-y: auto;
  display: none;
  @include adaptive(1024) {
    display: flex;
    flex-direction: column;
  }
  @include adaptive(480) {
    padding: 60px 10px 10px 10px;
  }
  &_open {
    transform: translateX(0);
  }

  &-socials {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &-link {
    height: 40px;
    color: $dark;
    &_social {
      color: $dark;
    }
  }

  &-languages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__navigation {
    margin-bottom: auto;
  }

  &__callback {
    width: 100%;
    @include adaptive(480) {
      height: 40px;
      min-height: 40px;
    }
  }

  &__mail {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    @include adaptive(480) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  &__phone {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    @include adaptive(480) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}
