@import "../helpers/core";
.registration {
  max-width: 490px;
  display: flex;
  flex-direction: column;
  padding: 60px 43px;
  @include adaptive(480) {
    padding: 20px;
  }
  &-tab {
    display: flex;
    flex-direction: column;
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__fields {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 40px;
    }

    &-label {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
    }

    &__link {
      font-size: 14px;
      line-height: 18px;
      font-weight: $text-medium;
      color: $dark;
      text-decoration: none;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2px;
        height: 1px;
        background: $dark;
      }
    }

    &__button {
      margin-bottom: 24px;
    }

    &-alternative {
      margin-bottom: 24px;
    }

    &-socials {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-social {
      color: $dark;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $dark;
      text-decoration: none;
      margin-right: 18px;
      font-size: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.size {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding: 31px 32px 70px 44px;
  @include adaptive(480) {
    padding: 20px;
  }
  &-img {
    max-width: 197px;
    margin-bottom: 16px;
  }
  &-description {
    margin-bottom: 24px;
    align-self: flex-start;
  }
  &-wrapper {
    max-height: 270px;
    overflow-y: auto;
    max-width: 100%;
    width: 100%;

    .simplebar-track.simplebar-vertical {
      width: 3px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #b6b6b6;
        left: 1px;
      }
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      top: 0;
      bottom: 0;
      background: $dark;
      opacity: 1;
      border-radius: 20px;
      left: 0;
      right: 0;
    }

    &_big {
      max-height: 406px;
    }
  }
  &-table {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-left: 1px;
    padding-bottom: 1px;
    min-width: 400px;

    &-item {
      padding: 7px 9px 18px;
      border: 1px solid $dark;
      margin: 0 0 -1px -1px;
      font-size: 12px;
      line-height: 120%;
      @include adaptive(480) {
        padding: 5px 3px;
      }

      &_gray {
        padding: 14px 15px 14px 18px;
        background: $gray-1;
      }
    }
  }

  &-content {
    margin-right: 42px;
    @include adaptive(480) {
      margin-right: 10px;
    }

    &__wrapper {
      margin-bottom: 28px;
      overflow-x: auto;
    }
  }

  &-quest {
    font-size: 16px;
    line-height: 150%;
    font-weight: $text-bold;
    margin-bottom: 26px;
    text-align: center;
  }

  &-contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    @include adaptive(480) {
      flex-direction: column;
    }


    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 29px;
      color: $dark;
      text-decoration: none;
      font-size: 14px;
      line-height: 18px;
      font-weight: $text-medium;
      @include adaptive(480) {
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
        @include adaptive(480) {
          margin-bottom: 0;
        }
        &::before {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: 2px;
          height: 1px;
          background: $dark;
        }
      }
      &__icon {
        margin-bottom: 13px;
      }
    }
  }

  &-item {
    height: max-content;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 14px;
      line-height: 18px;
      font-weight: $text-bold;
      margin-bottom: 8px;
    }
    &__text {
      font-size: 12px;
      line-height: 15px;
    }
  }
  &__button {
    margin-left: auto;
    margin-right: auto;
  }
}

.reviews {
  padding: 50px 50px 60px 50px;
  &-title {
    font-weight: $text-medium;
    font-size: 18px;
    margin-bottom: 18px;
  }
  &-form {
    display: flex;
    flex-direction: column;
  }
  &-description {
    margin-bottom: 14px;
  }
  &-fields {
    display: flex;
    flex-direction: column;
    &-rating {
      display: flex;
      padding: 0;
      margin: 0;
    }

    &__view {
      display: flex;
      transition: .3s;
    }

    &__item {
      display: flex;
      height: 33px;
      width: 33px;
      transition: .3s;
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        .reviews-fields__view {
          transform: translateY(-5px);
        }

        .reviews-fields__text {
          opacity: 1;
        }
      }
      img {
        width: 33px;
        height: 33px;
      }
    }

    &__text {
      position: absolute;
      top: calc(-100% - 5px);
      left: 50%;
      transform: translateX(-50%);
      background: $light;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 6px;
      filter: drop-shadow(0 0 7px rgba(0, 0, 0, .25));
      opacity: 0;
      pointer-events: none;
      transition: .3s;
      &::before {
        content: '';
        position: absolute;
        bottom: calc(-100% + 18px);
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $light;
        border-bottom: 7px solid transparent;
        width: 14px;
        height: 10px;
      }
    }

    &__checkbox {
      cursor: pointer;
      $checkbox: &;

      .checkbox__box:checked + .reviews-fields__view,
      &_active .reviews-fields__view {
        transform: translateY(-5px);
      }
    }
  }

  &-text {
    margin-top: 40px;
    display: none;
    flex-direction: column;

    &__description {
      margin-bottom: 8px;
    }

    &-label {

    }

    &-input {
      height: 130px;
      resize: none;
      padding-top: 10px;
    }

    &-button {
      margin-top: 20px;
    }
  }
}
