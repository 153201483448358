.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &_center {
    align-items: center;
  }

  &_internal {
    display: grid;
    grid-template-columns: 287px 1fr;
    gap: 30px 93px;
    @include adaptive(1024) {
      grid-template-columns: 100%;
    }
  }
}
