$dark: #222;
$attention: #fb5f63;
$light: #fff;
$gray: #b6b6b6;
$gray-1: #ddd;
$gray-2: #d3d3d3;
$gray-3: #757575;
$gray-4: #f0f0f0;

$level1: 24px;
$level2: 46px;
$level3: 18px;
$level4: 16px;
$level5: 8px;
$level6: 100px;
$level7: 12px;
$level8: 30px;
$level9: 40px;
$level10: 80px;
$level11: 60px;

$font-main:'geometria', sans-serif;

$text-normal: 400;
$text-medium: 500;
$text-bold: 700;
