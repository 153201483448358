.footer {
  display: flex;
  flex-direction: column;

  &-top {
    background: $dark;
    padding: 35px 0;

    &-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include adaptive(1024) {
        flex-wrap: wrap;
      }
      @include adaptive(640) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 377px;
    margin-right: 20px;
    @include adaptive(1024) {
      max-width: 100%;
      margin-bottom: 30px;
      margin-right: 0;
    }
    @include adaptive(640) {
      max-width: 360px;
      margin-bottom: 20px;
    }
  }

  &-logo {
    display: flex;
    width: 100%;
    max-width: 194px;
    margin-bottom: 36px;
    @include adaptive(1024) {
      margin-bottom: 20px;
    }
  }

  &-description {
    color: $light;
    margin-bottom: 40px;
    @include adaptive(1024) {
      margin-bottom: 20px;
      font-size: 14px;
    }
    @include adaptive(640) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  &-more {
    text-decoration: none;
    color: $light;
    width: max-content;

    &:hover {
      color: $gray-3;
      &::before {
        background: $gray-3;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      background: $light;
    }
  }

  &-interaction {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 289px;
    margin-top: 24px;
    @include adaptive(1024) {
      margin-top: 0;
    }
    @include adaptive(780) {
      max-width: 282px;
    }
    @include adaptive(640) {
      max-width: 360px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &-title {
    font-size: 11px;
    line-height: 127%;
    letter-spacing: .15em;
    text-transform: uppercase;
    color: $gray-3;
    margin-bottom: 25px;
    @include adaptive(780) {
      font-size: 10px;
    }
    @include adaptive(640) {
      margin-bottom: 10px;
    }
  }

  &-socials {
    display: flex;
    width: 100%;
    margin-bottom: 66px;
    @include adaptive(640) {
      margin-bottom: 20px;
    }

    &-item {
      color: $light;
      text-decoration: none;
      margin-right: 22px;
      font-size: 17px;
      transition: .3s;

      &:hover {
        color: $gray-3;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-pay {
    display: flex;
    width: 100%;

    &-item {
      text-decoration: none;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-client {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 213px;
    margin-right: 20px;
    margin-top: 24px;
    @include adaptive(1024) {
      margin-top: 0;
    }
    @include adaptive(780) {
      max-width: 184px;
    }
    @include adaptive(640) {
      max-width: 360px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &-list {
    &-item {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-link {
    font-size: 14px;
    color: $light;
    text-decoration: none;
    transition: .3s;

    &:hover {
      color: $gray-3;
    }
    @include adaptive(780) {
      font-size: 12px;
    }
  }

  &-button {
    margin-top: 24px;
    width: 154px;
    @include adaptive(640) {
      margin-top: 10px;
    }
  }

  &-services {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 231px;
    margin-top: 24px;
    @include adaptive(1024) {
      margin-top: 0;
    }
    @include adaptive(780) {
      max-width: 181px;
    }
    @include adaptive(640) {
      max-width: 360px;
    }
  }

  &-bottom {
    &-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
    }
    &-copy {
      font-size: 14px;
    }
  }
}
