.breadcrumbs {
  display: block;
  position: relative;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 18px;

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
  }

  &__item {
    font-size: 12px;
    line-height: 150%;
    color: $gray-3;
    padding: 0;
    margin-right: 11px;
    width: auto;
    display: inline;
    padding-right: 15px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 7px;
      border-right: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 3px solid $gray-3;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      &::before {
        display: none;
      }
    }
  }

  &__link {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;
    transition: all .4s ease-in-out;

    &:hover {
      color: $dark;
    }
  }
}
